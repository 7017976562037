.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  background-color: #f7f7f7;
}

.main {
  flex: 1;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 64px 12px 12px;
}

.formContainer {
  display: flex;
  justify-content: center;
}

.form {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
