.main {
  padding: 8px;
  overflow: auto;
}

.treeItem {
  padding-left: 12px;
}

.treeLabel {
  padding: 4px 0;
  display: flex;
  cursor: pointer;
  line-height: 1.2em;
}

.treeLabel:hover {
  text-decoration: underline;
  color: #3510d6;
}

.selected {
  font-weight: 600;
  background-color: darkgray;
  border-radius: 4px;
  border-color: darkgray;
  border-style: solid;
}

.treeArrow {
  padding-right: 24px;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center;
}

.right {
  background-image: url('../../images/arrow-right.svg');
}

.down {
  background-image: url('../../images/arrow-down.svg');
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.refresh.main,
.threeColorTemplate.main {
  padding: 12px 0;
}

.refresh.main > .treeItem,
.threeColorTemplate.main > .treeItem {
  padding-left: 0;
}

.refresh .treeItem,
.threeColorTemplate .treeItem {
  padding-left: 24px;
}

.refresh .treeLabel,
.threeColorTemplate .treeLabel {
  padding: 4px 24px;
}

.refresh .label,
.threeColorTemplate .label {
  min-width: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.refresh .selected,
.refresh .treeLabel:hover,
.threeColorTemplate .selected,
.threeColorTemplate .treeLabel:hover {
  color: #2B9DD4;
  text-decoration: underline;
}

.refresh .selected,
.threeColorTemplate .selected {
  border: none;
  background: none;
}
