.wrapper {
  /* position: fixed; */
  width: 100%;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
}

.main {
  padding: 0 22px;
  max-width: 1120px;
  margin: 0 auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgb(216, 216, 216); */
}

.title {
  padding-left: 4px;
  font-weight: 500;
  font-size: 1.4em;
  display: flex;
  align-items: center;
}

.centerPart {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 479px) {
  .title {
    font-size: 1em;
  }
}

.auth {
  display: flex;
  align-items: center;
  /* font-size: 0.9em; */
}

.auth img {
  width: 13px;
  opacity: 0.65;
  margin-right: 8px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
}

.infoIcon {
  width: 20px;
  margin-left: 12px;
  cursor: pointer;
}