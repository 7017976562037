.main {
  flex: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  /* padding: 6px; */
  /* background-color: rgb(251, 251, 251); */
}

.sideMenu {
  display: flex;
  position: relative;
  /*height: 100%;*/
  padding-top: 4px;
  flex: 1 1 auto;
}

.area {
  padding: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.areaContainer {
  flex: 1;
  display: flex;
  margin: 3px;
  min-height: 0;
  min-width: 0;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;

  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
  overflow: auto;
}

.viewMode > .areaContainer {
  margin: 0 6px 6px;
}
