.uploadtest {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  border: 10px;
}

.upload1 {
  border: 3px dashed rgb(129, 129, 129);
  border-radius: 25px;
  height: 100%;

  position: relative;
  text-align: center;
}

.text {
  padding-top: 30%;
  text-align: center;
  color: rgb(129, 129, 129);
}

.tips {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 30px;
}

.upload2 {
  margin-top: 20px;
  text-align: center;
}

.upload2 button {
  width: 100%;
  padding: 8px;
}
