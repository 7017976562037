.MuiCardContent-root {
  padding: 40px;
  color: rgb(24 18 224 / 87%);
}

.card {
  padding: 30px;
  color: rgb(74, 74, 117) !important;
}
.cc {
  background-color: rgb(243, 243, 243);
}
.par {
  text-align: left;
}

.parLi {
  color: rgb(37, 36, 36) !important;
}

.heen {
  color: blue;
}

html {
  scroll-behaviour: smooth;
}

.selected {
  color: rgb(192, 10, 10) !important;
}
.selectedH {
  color: rgb(143, 7, 7) !important;
}
.left {
  width: 40px;
}

.bbwhite {
  background-color: #ffffff !important;
}
.bbgrey {
  background-color: #f2f2f2 !important;
}

.bbwhitePaper {
  background-color: #ffffff !important;
  box-shadow: 10px 10px 5px grey;
}
.bbgreyPaper {
  background-color: #f2f2f2 !important;
  box-shadow: 10px 10px 5px grey;
}
.noneWhite {
  border: none;
  padding: 0px;
  margin: 0px;
}
.noneGrey {
  border: none;
  padding: 0px;
  margin: 0px;
  background-color: #f2f2f2 !important;
  background-color: #ffffff !important;
}
