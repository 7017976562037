.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* padding: 6px; */
  /* background-color: rgb(251, 251, 251); */
}

.selected {
  color: #2012dd;
  font-weight: 600;
}
.btn2 {
  text-align: left;
}
