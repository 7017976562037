.main {
  display: flex;
  align-items: center;
  padding: 8px;
  overflow: hidden;
}

.main select {
  max-width: 320px;
}

select {
  --dropdown-arrow-black-url: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  --dropdown-arrow-white-url: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
}

select.threeColorTemplate {
  padding-left: 1.6rem;
  /** for the dropdown indicator */
  appearance: none;
  background-image: var(--dropdown-arrow-black-url);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

select.threeColorTemplate:global(.primaryColor) {
  background-color: var(--threeColorTemplate-primary-action);
  color: hsl(0,0%,100%);

  /** for the dropdown indicator */
  background-image: var(--dropdown-arrow-white-url);
}

.label {
  padding-right: 12px;
  font-size: 0.9em;
  opacity: 0.5;
  padding-left: 6px;
}

.round
{
  border-radius: 20px;
}
