.main {
  width: 100%;
  padding: 8px;
  height: 100%;
}

.title {
  padding: 12px 6px;
  font-size: 1.3em;
  font-weight: 300;
  /* border-top: 1px solid #d8d8d8;*/
}

.main td,
.main th {
  padding: 4px 8px;
  /* border: 1px solid rgb(230, 230, 230); */
}

.rowNumberHeader {
  width: 3ch;
}

.main .rowNumber,
.main th {
  font-size: 0.9em;
  font-weight: normal;
  opacity: 0.5;
  /* text-align: center; */
}

.link {
  text-decoration: underline;

}

.link:hover {
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
}
.highlight {
  background-color: deepskyblue;
}
.centertext {
  text-align: center;
}
.inlineblock  {
  display: inline-block
}
