.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  background-color: #f7f7f7;
}

.main {
  flex: 1;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 64px 12px 12px;
}

.content {
  max-width: 700px;
  margin: 0 auto;
  padding: 32px 0;
}

.title {
  padding: 12px;
  font-size: 1.8em;
  font-weight: 300;
}

.subTitle {
  padding: 12px;
  font-size: 1.4em;
  font-weight: 300;
}

.paragr {
  padding: 12px;
}

.project {
  padding: 12px;
  /* border-bottom: 1px solid #e2e2e2; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 6px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
}

.project {
  display: flex;
  justify-content: space-between;
}

.project:hover {
  background-color: #f3f3f3;
}

.project:first-child {
  /* border-top: 1px solid #e2e2e2; */
}
