.main {
  width: 100%;
  padding: 8px;
  overflow: hidden !important;
}

.openButton {
  width: 100%;
}

.closeButton {
  position: absolute !important;
  margin-bottom: 15px;
  right: 0px !important;
  padding: 0px;
}

.divtext {
  width: 100%;
}

.textfield2 {
  width: 50%;
}
.weirdlabel {
  transform: translate(0px, 60px) scale(1) !important;
}

MuiInputLabel-outlined {
  transform: translate(12px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}

.defaultValueButton {
  position: relative !important;
  top: 10px;
  float: right;
  right: 10px;
}
.outer {
  padding: 4%;
}
.productTitle {
  color: rgb(0, 82, 110);
  font-size: 25px;
  font-family: Ciutadella;
}

.inner {
  width: 100%;
  padding: 50px;
  padding-left: 70px;
  padding-right: 20px;
  padding-bottom: 100px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px grey;
}

.buttonDiv {
  margin-top: 25px;
  display: inline-flex;
  flex: 0 0 auto;
}

.linkbutton {
  margin-top: 45px;
  margin-left: 2px;
  margin-right: 2px;
  letter-spacing: null;
  border-radius: 10px;
}
.verstrekken {
  background-color: rgb(110, 65, 65);
}

.appBar {
  position: 'relative';
}

.title {
  margin-left: 10px !important;
  flex: 1;
}

.vpane {
  height: 100%;
}
